import { format } from 'date-fns';

class ApiSender {
  constructor() {
  }

  async saveHistoryHe(uniqid, params, gbraid, wbraid, conversionGoalId, conversionGoalName, landingPageUrl, landingPageName) {
    try {
      const date = new Date();
      const formattedDateTime = format(date, 'yyyy-MM-dd HH:mm:ssxxx');
      const baseUrl = process.env.REACT_APP_IRAQ_BASE_URL;
      const response = await fetch(baseUrl + 'subscribe',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            transactionId: uniqid,
            campaignId: params.campaignId,
            trackerId: params.trackerId,
            googleTrackerId: params.gclid,
            mfId: params.mfid,
            mfToken: params.mf_token,
            gbraid: gbraid,
            wbraid: wbraid,
            conversionDateTime: formattedDateTime,
            conversionGoalId: conversionGoalId,
            conversionGoalName: conversionGoalName,
            landingPageUrl: landingPageUrl,
            landingPageName: landingPageName,
            p1: params.p1,
            p2: params.p2,
            p3: params.p3,
            p4: params.p4,
            p5: params.p5,
            p6: params.p6,
            p7: params.p7,
            p8: params.p8,
            p9: params.p9,
            p10: params.p10
          })
        });
      if (response.ok) {
        const redirectionUrl = 'http://www.social-sms.com/iq-dcb/HE/v1.2/oneclick/sub.php?serviceId=704&spId=203&shortcode=3368&uniqid=' + uniqid;
        window.location.href = redirectionUrl;
      }
    }
    catch (error) {
    }
  }

  async saveHistory(landingPageUrl, params) {
    try {
      let baseUrl = null;
      let endpoint = null;
      let extraParams = null;
      const isGoogleCampaign = params?.hasOwnProperty("gclid");
      if (isGoogleCampaign) {
        baseUrl = process.env.REACT_APP_GOOGLE_BASE_URL;
        endpoint = "google/visit";
        const list = ["gclid", "gbraid", "wbraid", "campaignId", "conversions", "cost_micros", "cpa", "utm_source", "utm_medium", "utm_campaign",
          "utm_term", "utm_content", "ad_group_id", "ad_group_name", "ad_id", "keyword_id", "keyword_text", "match_type", "clicks", "impressions",
          "ctr", "average_cpc", "conversion_date", "click_date", "geo_location", "device"];
        extraParams = Object.keys(params).reduce((acc, key) => {
          if (!list.includes(key)) {
            acc[key] = params[key];
          }
          return acc;
        }, {});
      }

      if (endpoint === null) {
        return;
      }

      await fetch(baseUrl + endpoint, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          gclid: params.gclid,
          gbraid: params.gbraid,
          wbraid: params.wbraid,
          campaign: params.campaignId,
          conversions: params.conversions,
          costMicros: params.cost_micros,
          cpa: params.cpa,
          utmSource: params.utm_source,
          utmMedium: params.utm_medium,
          utmCampaign: params.utm_campaign,
          utmTerm: params.utm_term,
          utmContent: params.utm_content,
          adGroupId: params.ad_group_id,
          adGroupName: params.ad_group_name,
          adId: params.ad_id,
          keywordId: params.keyword_id,
          keywordText: params.keyword_text,
          matchType: params.match_type,
          clicks: params.clicks,
          impressions: params.impressions,
          ctr: params.ctr,
          averageCpc: params.average_cpc,
          conversionDate: params.conversion_date,
          clickDate: params.click_date,
          geoLocation: params.geo_location,
          device: params.device,
          landingPageUrl: landingPageUrl,
          extraParams: JSON.stringify(extraParams)
        })
      });
    }
    catch (error) {
    }
  }

  async unsub(msisdn) {
    const baseUrl = process.env.REACT_APP_IRAQ_BASE_URL;
    const payload = {
      msisdn
    };

    try {
      const response = await fetch(`${baseUrl}kick`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (!response.ok) {
        return {
          success: false,
          message: result.code !== 5000
            ? result.message
            : 'Error Happened'
        };
      }

      return {
        success: result.status || false,
        message: result.message || ('No description available')
      };
    } catch (error) {
      console.error("Error unsub:", error.message);

      return {
        success: false,
        message: 'Error Happened'
      };
    }
  }

  async checkUserStatus(msisdn) {
    const baseUrl = process.env.REACT_APP_IRAQ_BASE_URL;
    const payload = {
      msisdn
    };

    try {
      const response = await fetch(`${baseUrl}status`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (!response.ok) {
        return {
          success: false,
          status: false,
          message: result.code !== 5000
            ? result.message
            : 'Error Happened',
          date: null,
          price: null,
          frequency: null
        };
      }

      const statusRsp = result.data;

      return {
        success: true,
        status: result.code === 2000,
        message: result.message || ('No description available'),
        date: statusRsp ? statusRsp.date : null,
        price: statusRsp ? statusRsp.price : null,
        frequency: statusRsp ? statusRsp.frequency : null
      };
    } catch (error) {
      console.error("Error check status:", error.message);

      return {
        success: false,
        status: false,
        message: 'Error Happened',
        date: null,
        price: null,
        frequency: null
      };
    }
  }
}

export default ApiSender;  