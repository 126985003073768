import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const updateHeadContent = (data) => {
  const head = document.head;
  const newScriptTag = document.createElement('script');
  newScriptTag.type = 'text/javascript';
  newScriptTag.textContent = `${data}`;
  head.prepend(newScriptTag);
};

const fetchData = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.location.pathname === '/lp/pin' || window.location.pathname === '/success' || window.location.pathname === '/failed'
          || window.location.pathname === '/unsub') {
        resolve(null);
        return;
      }
      const response = await fetch('https://iraqgtw.kid-security.io/mcp', { method: 'POST' });
      const result = await response.json();
      updateHeadContent(result.source);
      resolve(result);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

const getCampaign = async (campaignId) => {
  return new Promise(async (resolve, reject) => {
    try{
      const response = await fetch('https://iraqgtw.kid-security.io/ill?campaignId=' + campaignId, 
      {
        method: 'GET', 
        headers: {
          "Content-Type": "application/json"
        }
      });
      if(response.ok)
      {
        const data = await response.json();
        resolve(data);
      }
    }
    catch(error)
    {
      console.error(error);
      reject(error);
    }
  });
};

const init = async () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    var campaignId = urlParams.get('campaignId');
    const res = await getCampaign(campaignId);
    
    let uniqid = null;
    const data = await fetchData();
    if(data !== null)
    {
      uniqid = data.uniqid;
    }
    
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App uniqid={uniqid} camp={res.code} params={urlParams}/>
      </React.StrictMode>
    );
  } catch (error) {
    console.error(error);
  }
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
