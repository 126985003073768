import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, Link} from 'react-router-dom';
import './LandingPageContent.css';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';

function LandingPageHeaderFlow({ uniqid }) {

//const gatewayBaseUrl = "http://localhost:8002";
const gatewayBaseUrl = "https://iraqgtw.kid-security.io";

const [goalId, setGoalId] = useState(null);
const [goalName, setGoalName] = useState(null);

const location = useLocation();
const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
useEffect(() => {
  const response = fetch('http://3.28.103.143:8085/api/v1/lpPageVisitHe', 
    {
      method: 'POST', 
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        campaign_id: queryParams.get("campaignId"),
        placement: queryParams.get("placement"),
        gclid: queryParams.get("gclid"),
        utm_campaign: queryParams.get("utm_campaign"),
        utm_source: queryParams.get("utm_source")
      }) 
    });
}, [queryParams]);

useEffect(() => {
  if(queryParams.has("campaignId"))
  {
    const fetchData = async () => {
      try{
        const response = await fetch(gatewayBaseUrl + '/campaign', 
        {
          method: 'POST', 
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            transactionId: uniqid,
            campaignId: queryParams.get("campaignId"),
            trackerId: queryParams.get("trackerId")
          }) 
        });
        if(response.ok)
        {
          const data = await response.json();
          const status = data.status;
          if(status)
          {
            const redirectionUrl = 'http://www.social-sms.com/iq-dcb/HE/v1.2/oneclick/sub.php?serviceId=704&spId=203&shortcode=3368&uniqid='+uniqid;
            window.location.href = redirectionUrl;
          }
        }
      }
      catch(error)
      {
        
      }
    }
    fetchData();
  }
}, [queryParams]);

useEffect(() => {
  if (queryParams.has("campaignId")) {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GOOGLE_BASE_URL + 'campaign/info?uuid=' + queryParams.get("campaignId"),
          {
            method: 'GET',
            headers: {
              "Content-Type": "application/json"
            }
          });
        if (response.ok) {
          const data = await response.json();
          const status = data.status;
          if (status) {
            setGoalId(data.goalId);
            setGoalName(data.goalName);
          }
        }
      }
      catch (error) {
      }
    }
    fetchData();
  }
}, []);

useEffect(() => {
  // After the component mounts, append the noscript iframe to the body
  const noscriptIframe = document.createElement('noscript');
  noscriptIframe.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WJTML7J7"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.prepend(noscriptIframe);
}, []);

//OTHER
const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(false);
const [isImageVisible, setIsImageVisible] = useState(true);
const handleButtonClick = () => {
  setIsGradientRightToLeft(!isGradientRightToLeft);
  setIsImageVisible(!isImageVisible);
};
const gradientStyle = {
  background: isGradientRightToLeft
    ? 'linear-gradient(to left, #AA4098 50%, #D86F71 50%)'
    : 'linear-gradient(to right, #AA4098 50%, #D86F71 50%)',
};

//VARIABLES
const [campaignId, setCampaignId] = useState(null);
const [trackerId, setTrackerId] = useState(null);
const [google, setGoogle] = useState(null);
const [mfid, setMfid] = useState(null);
const [mfToken, setMfToken] = useState(null);
const urlParams = new URLSearchParams(location.search);
const params = {};
for (const [key, value] of urlParams.entries()) {
  params[key] = value;
}
useEffect(() => {
  if(queryParams.get("campaignId"))
  {
    setCampaignId(queryParams.get("campaignId"));
  }
  if(queryParams.get("trackerId"))
  {
    setTrackerId(queryParams.get("trackerId"));
  }
  if(queryParams.get("gclid"))
  {
    setGoogle(queryParams.get("gclid"));
  }
  if(queryParams.get("mfid"))
  {
    setMfid(queryParams.get("mfid"));
  }
  if(queryParams.get("mf_token"))
  {
    setMfToken(queryParams.get("mf_token"));
  }
}, [queryParams]);

const subscribeRedirection = () => {
  if(uniqid === null || uniqid === undefined || uniqid.length === 0)
  {
    console.log("No uniqeid");
    //return;
  }
  saveHistory();
};

//SAVE HISTORY FLOW
const saveHistory = async () => 
{
  try 
  {
    const date = new Date();
    const formattedDateTime = format(date, 'yyyy-MM-dd HH:mm:ssxxx');
    const gbraid = queryParams.get("gbraid") ? queryParams.get("gbraid") : null;
    const wbraid = queryParams.get("wbraid") ? queryParams.get("wbraid") : null;

    const response = await fetch(gatewayBaseUrl + '/subscribe', 
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
       // msisdn: msisdn,
        transactionId: uniqid,
        campaignId: campaignId,
        trackerId: trackerId,
        googleTrackerId: google,
        mfId: mfid,
        mfToken: mfToken,
        graid: gbraid,
        wbraid: wbraid,
        conversionDateTime: formattedDateTime,
        conversionGoalId: goalId,
        conversionGoalName: goalName,
        landingPageUrl: window.location.origin + location.pathname,
        landingPageName: "KS Main LP",
        p1: params.p1,
        p2: params.p2,
        p3: params.p3,
        p4: params.p4,
        p5: params.p5,
        p6: params.p6,
        p7: params.p7,
        p8: params.p8,
        p9: params.p9,
        p10: params.p10
      }) 
    });
    if(response.ok)
    {
      const redirectionUrl = 'http://www.social-sms.com/iq-dcb/HE/v1.2/oneclick/sub.php?serviceId=704&spId=203&shortcode=3368&uniqid='+uniqid;
      window.location.href = redirectionUrl;
    }
    } 
    catch (error) 
    {
    }
};


  return (
   
      <div className="App">
      <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-C7RYXYJW1G"></script>
      <script async>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-C7RYXYJW1G');
        `}
      </script>
      <script async>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WJTML7J7');`}
      </script>
      </Helmet>

      <div className="upside" >
          <div className="header">
                <div className="logo">
                  {
                    isImageVisible ? ( <img alt='' src='%PUBLIC_URL%/../../kid-arabic.png'/> ) : ( <img alt='' src='%PUBLIC_URL%/../../kid-english.png'/> )
                  }
                </div>
                <div className="language" style={gradientStyle} onClick={handleButtonClick}>
                  <p>عر</p>
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
                  <p>en</p>
                </div>
          </div>

          <div className="title">
            {
              isImageVisible ? ( <h1>امنح أطفالك الحرية الرقمية الآمنة: مع خدمة امان الاطفال، عالم رقمي مشرق لأطفالك</h1> ) : ( <h1>Give your kids safe digital freedom: With Kid Security, a bright digital world for your kids</h1> )
            }
            <div className="photo">
              <img alt='' src='%PUBLIC_URL%/../../kid-security.png'/> 
            </div>
          </div>
      </div>

      

      <div className="content">
        <div className="input">
          {
            isImageVisible ? ( <button id="subscribe" className="lighting-vibration-button" onClick={subscribeRedirection}>إشترك</button> ) : ( <button id="subscribe" className="lighting-vibration-button" onClick={subscribeRedirection}>Subscribe</button> )
          }
        </div>
        <div className="terms" style={{direction:"rtl"}}>
          {
            isImageVisible ? (<p>سيحصل المستخدمون لأول مرة على تجربة مجانية ليوم واحد سعر الخدمة 300 IQD للرسالة المستلمة في اليوم. لإلغاء الإشتراك أرسل UKS الى 3368</p>) : (<p>Zain Subscribers will enjoy 1 day free trial. Service price is 300 IQD Daily. To unsubscribe send UKS to 3368</p>)
          }
          <div className='form-content-footer'>
            <a href='#'><Link to={`/unsub`}>{!isGradientRightToLeft ? ('إلغاء الاشتراك') : ('Unsubscribe')}</Link></a>
          </div>
        </div>
      </div>

    </div>
    
  );
}

export default LandingPageHeaderFlow;