import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './KickPageStyle.css';
import ApiSender from './common/ApiLogic';

function KickPage() {

    const [firstStep, setFirstStep] = useState(true);
    const [secondStep, setSecondStep] = useState(false);
    const [warning, setWarning] = useState(false);
    const [active, setActive] = useState(false);
    const [msisdn, setMsisdn] = useState(null);
    const [language, setLanguage] = useState('AR');
    const [message, setMessage] = useState(null);
    const [date, setDate] = useState(null);
    const [price, setPrice] = useState(null);
    const [frequency, setFrequency] = useState(null);
    const [unsubscribe, setUnsubscribe] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        async function begin() {
            const urlParams = new URLSearchParams(window.location.search);
            const msisdnValue = urlParams.get('msisdn');
            if (!msisdnValue || !isZain(msisdnValue)) {
                return;
            }
            setMsisdn(msisdnValue);

            const apiSender = new ApiSender();
            const checkUserStatusResult = await apiSender.checkUserStatus(msisdnValue);
            if (!checkUserStatusResult.success) {
                setMessage(checkUserStatusResult.message);
            }
            else {
                setFirstStep(false);
                setSecondStep(true);
                const status = checkUserStatusResult.status;
                const message = checkUserStatusResult.message;
                if (!status) {
                    setMessage(message);
                    return;
                }
                setActive(true);
                const date = checkUserStatusResult.date;
                const price = checkUserStatusResult.price;
                const frequency = checkUserStatusResult.frequency;
                setDate(date);
                setPrice(price);
                setFrequency(frequency);
            }
        }
        begin();
    }, []);


    const showUnsubscribeOption = () => {
        document.getElementById('unsub-btn').disabled = true;
        document.getElementById('loading-spinner-unsub').style.display = 'inline-flex';
        const timer = setTimeout(() => {
            setUnsubscribe(true);
            document.getElementById('loading-spinner-unsub').style.display = 'none';
        }, 5000);
        return () => clearTimeout(timer);
    }

    const showUnsubscribeConfirm = () => {
        setShowPopup(true);
    }

    const confirmUnsubscribe = async () => {
        document.getElementById('loading-spinner-unsub-btn').style.display = 'inline-flex';
        document.getElementById('unsub-confirm-btn').disabled = true;
        document.getElementById('unsub-cancel-btn').disabled = true;

        const apiSender = new ApiSender();
        const result = await apiSender.unsub(msisdn);
        if (result.success) {
            document.getElementById('loading-spinner-unsub-btn').style.display = 'none';
            document.getElementById('unsub-confirm-btn').disabled = false;
            document.getElementById('unsub-cancel-btn').disabled = false;
            setWarning(true);
            document.getElementById('warning-msg').textContent = result.message;
        }
        else {
            setShowPopup(false);
            document.getElementById('loading-spinner-unsub-btn').style.display = 'none';
            document.getElementById('unsub-confirm-btn').disabled = false;
            document.getElementById('unsub-cancel-btn').disabled = false;
            setWarning(true);
            document.getElementById('warning-msg').textContent = result.message;
        }
    }

    const cancelUnsubscribe = () => {
        setShowPopup(false);
        document.getElementById('loading-spinner-unsub-btn').style.display = 'none';
        document.getElementById('unsub-confirm-btn').disabled = false;
        document.getElementById('unsub-cancel-btn').disabled = false;
    }

    const handleMsisdnChange = (event) => {
        const newMsisdn = event.target.value;
        setMsisdn(newMsisdn);
        document.getElementById('country-code').style.color = 'black';
        setWarning(false);
    }

    const handleMsisdnFocus = () => {
        document.getElementById('country-code').style.color = 'black';
    }

    const handleMsisdnBlur = () => {
        if (msisdn === null || msisdn === undefined || msisdn.length === 0) {
            document.getElementById('country-code').style.color = '#999';
        }
    }

    const checkUserStatus = async () => {
        if (!isZain(msisdn)) {
            document.getElementById('warning-msg').textContent = language === 'EN' ? 'Incorrect Number Format' : 'رقم غير صحيح';
            setWarning(true);
            return;
        }

        const apiSender = new ApiSender();
        const checkUserStatusResult = await apiSender.checkUserStatus(msisdn);
        if (!checkUserStatusResult.success) {
            setMessage(checkUserStatusResult.message);
        }
        else {
            setFirstStep(false);
            setSecondStep(true);
            const status = checkUserStatusResult.status;
            const message = checkUserStatusResult.message;
            if (!status) {
                setMessage(message);
                return;
            }
            setActive(true);
            const date = checkUserStatusResult.date;
            const price = checkUserStatusResult.price;
            const frequency = checkUserStatusResult.frequency;
            setDate(date);
            setPrice(price);
            setFrequency(frequency);
        }
    }

    const isZain = (msisdn) => {
        if (msisdn !== null && msisdn !== undefined) {
            return (msisdn.length === 10 && (msisdn.startsWith('78') || msisdn.startsWith('79')));
        }
        return false;
    }

    return (
        <div className="Aa000">
            <div className='container'>
                <div className='header'>
                    <div className='header-right'>
                        <div className='logo'><img alt='Kid Security' src='%PUBLIC_URL%/../../kid-english.png' /></div>
                    </div>
                </div>
                <div className='form-content'>
                    {firstStep &&
                        <div className='form-content-middle'>
                            <div className='title'>
                                <h4 id='headup-title'>Please enter your mobile number.<br /><br />.يرجى إدخال رقم هاتفك المحمول</h4>
                            </div>
                            <div className='arrows' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '1.25rem' }}>
                                {firstStep && <svg fill="#000000" height="2em" width="2em" viewBox="0 0 330 330" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"><path d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z" /></svg>}
                                <div id='msisdn-input' className={`input ${warning ? 'vibrate' : ''}`}>
                                    <span id='country-code' className='country-code'>+964</span>
                                    <input type='tel' value={msisdn} placeholder='7x xxxx xxxx' autoComplete='off' minLength={10} maxLength={10} required onChange={handleMsisdnChange} onFocus={handleMsisdnFocus} onBlur={handleMsisdnBlur} />
                                </div>
                                {firstStep && <svg fill="#000000" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 400.004 400.004" xmlSpace="preserve"><g><path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z" /></g></svg>}
                            </div>
                            <div id='warning-msg-div' className={`warning-msg ${warning ? 'active' : ''}`}>
                                <span id='warning-msg'></span>
                            </div>
                            <div className='kick-span'>
                                <button onClick={checkUserStatus}>Unsubscribe<br /><br />الغاء اشتراك</button>
                            </div>
                        </div>
                    }
                    {secondStep &&
                        <div className='unsub-form'>{active ? (<div>
                            <div className='title' style={{ alignItems: 'flex-start' }}>
                                <h4 id='headup-title'>Welcome {msisdn}</h4>
                                <hr />
                                <h4 className='headup-title-unsub'>Your subscriptions</h4>
                                <hr />
                                <h4 className='headup-title-unsub'>Kid Security</h4>
                                <hr />
                            </div>
                            <div className='form-content-middle'>
                                <div className='unsub-details'>
                                    <div><span>Subscription Date:</span> <span className='unsub-details-value'>{date}</span></div><br></br>
                                    <div><span>Price:</span> <span className='unsub-details-value'>{price}</span></div><br></br>
                                    <div><span>Frequency:</span> <span className='unsub-details-value'>{frequency}</span></div><br></br>
                                    <div><span>Access Portal:</span> <a href='#'>Click here to access portal</a></div><br></br>
                                    {unsubscribe && (
                                        <div><span>To unsubscribe:</span> <a href='#' onClick={showUnsubscribeConfirm}>Unsubscribe from Kid Security</a></div>
                                    )}
                                    <div id="loading-spinner-unsub" className="spinner"></div>
                                </div>
                                <hr />
                            </div>

                            <div id='warning-msg-div' className={`warning-msg ${warning ? 'active' : ''}`}>
                                <span id='warning-msg'></span>
                            </div>

                            {!unsubscribe && <div id='form-content-footer-unsub' className='form-content-footer' style={{ direction: language === 'EN' ? "ltr" : "rtl", justifyContent: 'center' }}>
                                <Link to='#' style={{ textDecoration: 'none' }}><span>The Tax amount was collected to prepaid customers upon charging<br></br>
                                    To unsubscribe</span> <a id='unsub-btn' href='#' onClick={showUnsubscribeOption}>click here</a></Link>
                            </div>}
                            {showPopup && (
                                <div className="popup">
                                    <div className="popup-content">
                                        <p>Are you sure you want to unsubscribe from Kid Security ?</p>
                                        <button id='unsub-confirm-btn' style={{ display: 'block' }} onClick={confirmUnsubscribe}>
                                            Confirm
                                            <div id="loading-spinner-unsub-btn" className="spinner"></div>
                                        </button>
                                        <button id="unsub-cancel-btn" style={{ display: 'block' }} onClick={cancelUnsubscribe}>Cancel</button>
                                    </div>
                                </div>
                            )}
                        </div>) : (<div>
                            <div className='title'>
                                <h4 id='headup-title'>Welcome {msisdn}</h4>
                            </div>
                            <div className='form-content-middle'>
                                <div className='unsub-inactive-msg'>
                                    <span>{message}</span>
                                </div>
                            </div>
                        </div>)}</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default KickPage;
