
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPageSuccess from './LandingPageSuccess';
import LandingPageFailed from './LandingPageFailed';
import LandingPageHeaderFlow from './LandingPageHeaderFlow';
import LandingPagePinFlow from './LandingPagePinFlow';
import Page1711 from './ks/Page1711';
import KickPage from './KickPage';


function App({uniqid, camp, params}) 
{
  return (
    <Router>
      <Routes>
        <Route path="/lp/he" element={camp === 2001 ? (<Page1711 uniqid={uniqid} />) : 
            (camp === 2002 ? (<Page1711 uniqid={uniqid} />) : (<LandingPageHeaderFlow uniqid={uniqid} />))} />
        <Route path="/lp/pin" element={<LandingPagePinFlow />} />
        <Route path="/success" element={<LandingPageSuccess />} />
        <Route path="/failed" element={<LandingPageFailed />} />
        <Route path="/unsub" element={<KickPage />} />
      </Routes>
    </Router>
  );
}

export default App;